<template>
  <div>
    <v-container class="page-title page-title--advanced">
      <v-row>
        <v-col class="page-title__title-box">
          <h4 class="font-weight-light">{{ $t('heading.instance.advanced.log.title') }}</h4>
        </v-col>
        <v-col class="page-title__filter-controls">
          <data-iterator-filter-controls
            :showSearchTerm="true"
            :searchTerm="searchTerm"
            @update:searchTerm="handleSearchTermChange"
          ></data-iterator-filter-controls>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="px-0">
      <v-row>
        <v-col cols="12">
          <logs-table
            :items="itemsLoading ? [] : items"
            :itemsLoading="itemsLoading"
            :itemsPerPage="itemsPerPage"
            :itemsTotal="itemsTotal"
            :headers="headers"
            :page="page"
            :possibleItemsPerPage="[[2, 5, 10, 20, 100]]"
            :searchTerm="searchTerm"
            :sortBy="sortBy"
            :sortDesc="sortDesc"
            @update:page="changePage"
            @update:itemsPerPage="changePerPage"
            @update:sortDesc="changeSortDesc"
            @update:sortBy="changeSortBy"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { fetchInstanceLogs } from "../../../router/fetchData";

import DataIteratorPageMixin from "../../../mixins/DataIteratorPageMixin";
import CustomTablePageMixin from "../../../mixins/CustomTablePageMixin";

import LogsTable from "../../../components/dataIterator/tables/LogsTable.vue";
import DataIteratorFilterControls from "../../../components/dataIterator/DataIteratorFilterControls.vue";

export default {
  components: {
    LogsTable,
    DataIteratorFilterControls,
  },
  mixins: [DataIteratorPageMixin, CustomTablePageMixin],
  data: function () {
    return {
      fetching: true,
      headers: [
        {
          text: 'user',
          value: "user",
          sortable: false,
        },
        {
          text: 'actions',
          value: "action",
          sortable: false,
        },
        {
          text: 'date',
          value: "date",
          align: "end",
          sortable: true,
        },
      ],
      sortBy: {
        text: 'date',
        value: "date",
        sortable: true,
      },
      sortDesc: true,
      items: [],
      page: 1,
      itemsPerPage: 10,
      itemsLoading: true,
      searchTerm: "",
      itemsTotal: 0,
      applicationId: this.$route.params.id,
    };
  },
  mounted() {
    this.loadLogs();
  },
  methods: {
    async loadLogs() {
      this.itemsLoading = true;
      let { data, meta, error } = await fetchInstanceLogs(
        this.applicationId,
        this.page,
        this.itemsPerPage,
        this.sortBy.value,
        this.sortDesc,
        this.searchTerm
      );
      this.itemsLoading = false;
      if (error) {
        // console.error("ERROR: ", error);
        return;
      }
      this.itemsTotal = meta.total;
      this.items = data;
    },
    changePage(page) {
      this.page = page;
      this.loadLogs();
    },
    changePerPage(perPage) {
      this.page = 1;
      this.itemsPerPage = perPage;
      this.loadLogs();
    },
    changeSortBy: function (data) {
      this.page = 1;
      this.sortBy = data;
      this.loadLogs();
    },
    changeSortDesc: function (data) {
      this.page = 1;
      this.sortDesc = data;
      this.loadLogs();
    },
    handleSearchTermChange: function (newValue) {
      this.page = 1;
      this.searchTerm = newValue;
      this.loadLogs();
    },
  },
  beforeRouteEnter: function (_, __, next) {
    next((vm) => {
      vm.fetching = false;
    });
  },
};
</script>
